import firebase from "firebase";
import "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDV3i4aEP6Am8CnUH3qGWvy-l2ZFX6z4XI",
  authDomain: "receipe-8d45e.firebaseapp.com",
  databaseURL: "https://receipe-8d45e.firebaseio.com",
  projectId: "receipe-8d45e",
  storageBucket: "receipe-8d45e.appspot.com",
  messagingSenderId: "624828575381",
  appId: "1:624828575381:web:8ccda6d157c9fe0853359c",
  measurementId: "G-WTP2N1NB9Q",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
