import React, { useState } from "react";
import { connect } from "react-redux";
import { updateRecipe } from "../../redux/actions/recipeActions";

import EditModal from "./../commons/EditModal";

import PropTypes from "prop-types";

import { Button } from "@material-ui/core/";

const EditRecipe = ({ id, recipe, updateRecipe }) => {
  const [openModal, setOpenModal] = useState(false);

  const submitRecipe = (e, recipe) => {
    e.preventDefault();
    updateRecipe(id, recipe);
    setOpenModal(false);
  };

  return (
    <div className="editRecipe">
      <Button onClick={() => setOpenModal(true)}>Edit Post</Button>

      <EditModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        recipe={recipe}
        submitRecipe={submitRecipe}
      />
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  updateRecipe,
};

EditRecipe.propTypes = {
  id: PropTypes.string.isRequired,
  recipe: PropTypes.object.isRequired,
  updateRecipe: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRecipe);
