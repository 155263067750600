import * as types from "./actionTypes";
import { db } from "../../firebase";

export function loadRecipesSuccess(recipes) {
  return { type: types.LOAD_RECIPES_SUCCESS, recipes };
}

export function createRecipeSuccess(recipes) {
  return { type: types.CREATE_RECIPE_SUCCESS, recipes };
}

export function updateRecipeSuccess() {
  return { type: types.UPDATE_RECIPE_SUCCESS };
}

export function deleteRecipeOptimistic() {
  return { type: types.DELETE_RECIPE_OPTIMISTIC };
}

export function addRecipe(recipe) {
  return function (dispatch) {
    db.collection("receipes")
      .add(recipe)
      .then(function (docRef) {
        dispatch(
          createRecipeSuccess({
            id: docRef.id,
            recipe,
          })
        );
        //console.log("Document written with ID: ", docRef);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };
}

export function updateRecipe(id, recipe) {
  return function (dispatch) {
    db.collection("receipes")
      .doc(id)
      .set(recipe)
      .then(() => {
        //console.log("Document successfully written!");
        dispatch(updateRecipeSuccess());
      })
      .catch(function (error) {
        console.error("Saving recipes failed: ", error);
      });
  };
}

export function deleteRecipe(id) {
  return function (dispatch) {
    return db
      .collection("receipes")
      .doc(id)
      .delete()
      .then(() => {
        dispatch(deleteRecipeOptimistic());
        console.log("document sussfully deleted");
      })
      .catch((error) => {
        console.error("Error removing recipe: ", error);
      });
  };
}

export function loadRecipes() {
  return function (dispatch) {
    return db.collection("receipes").onSnapshot(
      (snapshot) => {
        //everytime there is an update on receipes, this code fires.
        let recipes = snapshot.docs.map((doc) => ({
          id: doc.id,
          recipe: doc.data(),
        }));
        dispatch(loadRecipesSuccess(recipes));
      },
      (error) => {
        //...
        console.error("loading recipes failed", error);
      }
    );
  };
}
