import React, { useState } from "react";
import PropTypes from "prop-types";

import EditRecipe from "./EditRecipe";
import DeleteRecipe from "./DeleteRecipe";

import "./RecipeCard.scss";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#1fbe73",
  },
}));

const RecipeCard = ({
  user,
  id,
  name,
  description,
  size,
  ingredients,
  instructions,
  imageUrl,
  time,
  author,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);

  const isAuthor = () => {
    return user?.name === author;
  };

  const handleActionMenuClick = (event) => {
    setActionMenuAnchor(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setActionMenuAnchor(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="card">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {author[0]}
            </Avatar>
          }
          action={
            isAuthor() ? (
              <IconButton aria-label="settings" onClick={handleActionMenuClick}>
                <MoreVertIcon />
              </IconButton>
            ) : null
          }
          title={name}
          subheader={time}
        />

        {/*todo add to CardActionMenu component */}
        {isAuthor() ? (
          <Menu
            id="simple-menu"
            anchorEl={actionMenuAnchor}
            keepMounted
            open={Boolean(actionMenuAnchor)}
            onClose={handleActionMenuClose}
          >
            <MenuItem onClick={handleActionMenuClose}>
              <EditRecipe
                id={id}
                recipe={{
                  id,
                  name,
                  description,
                  size,
                  ingredients,
                  instructions,
                  imageUrl,
                  author,
                }}
              />
            </MenuItem>
            <MenuItem onClick={handleActionMenuClose}>
              <DeleteRecipe id={id} />
            </MenuItem>
          </Menu>
        ) : null}
        <CardMedia
          className={classes.media}
          image={imageUrl || "#"}
          title={name}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <h4 className="card__title">食量： {size}</h4>
            <h4 className="card__title">材料:</h4>
            <div>
              {ingredients.map((ingredient) => (
                <div key={ingredient.name} className="tr">
                  <span className="td">{ingredient.name}</span>
                  <span className="td">{ingredient.unit}</span>
                </div>
              ))}
            </div>

            <h4 className="card__title">步骤:</h4>
            <ol className="card__steps">
              {instructions.map((instruction) => (
                <li className="card__step" key={instruction}>
                  {instruction}
                </li>
              ))}
            </ol>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

RecipeCard.propTypes = {
  user: PropTypes.object,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  instructions: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  imageUrl: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default RecipeCard;
