import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { loadRecipes } from "./../redux/actions/recipeActions";
import { logoUrl } from "../util/const";
import { convertDate } from "../util";

import RecipeCard from "./recipe/RecipeCard";
import CreateRecipe from "./recipe/CreateRecipe";
import Login from "./users/Login";

import "./Main.scss";

const Main = ({ user, recipes, loadRecipes }) => {
  useEffect(() => {
    if (recipes.length === 0) {
      loadRecipes();
    }
  }, [recipes, loadRecipes]);

  return (
    <div className="app">
      <div className="cards__header">
        <center>
          <img src={logoUrl} alt="Logo" className="app__headerImage" />
        </center>
        <div className="cards__header_block">
          {user ? <CreateRecipe /> : null}
          <Login />
        </div>
      </div>

      <div className="cards">
        {recipes.map(({ id, recipe }) => (
          <RecipeCard
            key={id}
            id={id}
            user={user}
            name={recipe.name}
            size={recipe.size}
            ingredients={recipe.ingredients}
            instructions={recipe.instructions}
            imageUrl={recipe.imageUrl}
            description={recipe.description}
            time={convertDate(recipe.timestamp?.seconds)}
            author={recipe.author}
          />
        ))}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    recipes: state.recipes,
  };
}

const mapDispatchToProps = {
  loadRecipes,
};

Main.propTypes = {
  user: PropTypes.object,
  recipes: PropTypes.array.isRequired,
  loadRecipes: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
