import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function recipesReducer(state = initialState.recipes, action) {
  switch (action.type) {
    case types.LOAD_RECIPES_SUCCESS:
      return action.recipes;
    case types.CREATE_RECIPE_SUCCESS:
      return state;
    case types.UPDATE_RECIPE_SUCCESS:
      return state;
    case types.DELETE_RECIPE_OPTIMISTIC:
      return state;
    default:
      return state;
  }
}
