import React, { useState, useEffect } from "react";
import { logoUrl } from "../../util/const";
import { connect } from "react-redux";
import { auth } from "./../../firebase";

import {
  createUser,
  signinUser,
  loadUser,
} from "./../../redux/actions/userActions";

import PropTypes from "prop-types";

import "./Login.scss";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    maxWidth: 560,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Login = ({ user, createUser, signinUser, loadUser }) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signUp = (e) => {
    e.preventDefault();

    createUser(email, password, username);

    setOpen(false);
  };

  const signIn = (e) => {
    e.preventDefault();

    signinUser(email, password);

    setOpenSignIn(false);
  };

  useEffect(() => {
    const unsubscribe = loadUser();
    return () => {
      //perform cleanup actions
      unsubscribe();
    };
  }, [loadUser]);

  return (
    <div className="login">
      <Modal
        className="login__modal"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={modalStyle} className={classes.paper}>
          <center>
            <img src={logoUrl} alt="Logo" className="app__headerImage" />
          </center>

          <form className="app__signup">
            <Input
              placeholder="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <Input
              placeholder="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" onClick={signUp}>
              Submit
            </Button>
          </form>
        </div>
      </Modal>
      <Modal
        className="login__modal"
        open={openSignIn}
        onClose={() => setOpenSignIn(false)}
      >
        <div style={modalStyle} className={classes.paper}>
          <center>
            <img src={logoUrl} alt="Logo" className="app__headerImage" />
          </center>

          <form className="app__signin">
            <Input
              placeholder="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" onClick={signIn}>
              Submit
            </Button>
          </form>
        </div>
      </Modal>
      <div className="login__header">
        {user ? (
          <Button onClick={() => auth.signOut()}>Logout</Button>
        ) : (
          <div className="login__loginContainer">
            <Button onClick={() => setOpenSignIn(true)}>Sign in</Button>
            <Button onClick={() => setOpen(true)}>Sign up</Button>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = {
  createUser,
  signinUser,
  loadUser,
};

Login.propTypes = {
  user: PropTypes.object,
  createUser: PropTypes.func.isRequired,
  signinUser: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
