import React, { useState } from "react";
import { connect } from "react-redux";
import { addRecipe } from "./../../redux/actions/recipeActions";

import EditModal from "../commons/EditModal";

import PropTypes from "prop-types";

import "./CreateRecipe.scss";

import { Button } from "@material-ui/core/";

const CreateRecipeModel = ({ addRecipe, author }) => {
  const [openModal, setOpenModal] = useState(false);
  const [recipe, setRecipe] = useState({ author: author });

  const submitRecipe = (e, recipe) => {
    e.preventDefault();
    addRecipe(recipe);
    setOpenModal(false);
  };

  return (
    <div className="newRecipe">
      <Button
        className="newRecipe__button"
        variant="contained"
        onClick={() => setOpenModal(true)}
      >
        New Recipe
      </Button>
      <EditModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        recipe={recipe}
        setRecipe={setRecipe}
        submitRecipe={submitRecipe}
      />
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    author: state.user.name,
    recipe: ownProps.recipe,
  };
}

const mapDispatchToProps = {
  addRecipe,
};

CreateRecipeModel.propTypes = {
  author: PropTypes.string.isRequired,
  addRecipe: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecipeModel);
