import React from "react";
import { connect } from "react-redux";
import { deleteRecipe } from "../../redux/actions/recipeActions";

import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

function DeleteRecipe({ id, deleteRecipe }) {
  const handleClick = () => {
    if (window.confirm("Are you sure to delete this recipe?")) {
      deleteRecipe(id);
    }
  };

  return (
    <>
      <Button onClick={handleClick}>Delete Post</Button>
    </>
  );
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  deleteRecipe,
};

DeleteRecipe.propTypes = {
  id: PropTypes.string.isRequired,
  deleteRecipe: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRecipe);
