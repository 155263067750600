export const CREATE_RECIPE_SUCCESS = "CREATE_RECIPE_SUCCESS";
export const LOAD_RECIPES_SUCCESS = "LOAD_RECIPES_SUCCESS";
export const UPDATE_RECIPE_SUCCESS = "UPDATE_RECIPE_SUCCESS";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_IN";

//
export const DELETE_RECIPE_OPTIMISTIC = "DELETE_RECIPE_OPTIMISTIC";
