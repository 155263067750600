import { combineReducers } from "redux";
import recipes from "./recipeReducer";
import user from "./userReducer";

const rootReducer = combineReducers({
  recipes,
  user,
});

export default rootReducer;
