import React, { useState } from "react";
import PropTypes from "prop-types";
import firebase from "firebase";

import ImageUpload from "./ImageUpload";

import "./EditModal.scss";

import { Modal, Button, Input, TextField } from "@material-ui/core/";

function EditModal({ openModal, setOpenModal, recipe, submitRecipe }) {
  const [name, setName] = useState(recipe.name || "");
  const [description, setDescription] = useState(recipe.description || "");
  const [size, setSize] = useState(recipe.size || "");
  const [ingredients, setIngredients] = useState(
    recipe.ingredients || [{ name: "", unit: "" }]
  );
  const [instructions, setInstructions] = useState(
    recipe.instructions || ["", ""]
  );
  const [imageUrl, setImageUrl] = useState(recipe.imageUrl || "");

  const handleIngredientChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...ingredients];
    list[index][name] = value;
    setIngredients(list);
  };
  const handleInstructionChange = (e, index) => {
    const value = e.target.value;
    const list = [...instructions];
    list[index] = value;
    setInstructions(list);
  };

  const removeField = (index, list, setList) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  };

  const addInstructionField = () => {
    setInstructions([...instructions, [""]]);
  };

  const addIngredientField = () => {
    setIngredients([...ingredients, { name: "", unit: "" }]);
  };

  const handleSubmit = (event) => {
    submitRecipe(event, {
      name,
      description,
      size,
      ingredients,
      instructions,
      imageUrl,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      author: recipe.author,
    });
    setName("");
    setDescription("");
    setSize("");
    setIngredients([{ name: "", unit: "" }]);
    setInstructions(["", ""]);
    setImageUrl("");
  };

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="newRecipe__modal">
          <h2>{recipe.name ? "Edit Recipe" : "New Recipe"}</h2>

          <form className="newRecipe__form">
            <ImageUpload imageUrl={imageUrl} setImageUrl={setImageUrl} />
            <Input
              placeholder="Recipe title"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <Input
              placeholder="Description"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Input
              placeholder="Number of servings"
              type="text"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />

            <h4>Ingredients</h4>
            {ingredients.map(({ name, unit }, i) => {
              return (
                <div className="newRecipe__block" key={i}>
                  <div className="newRecipe__formBlock">
                    <TextField
                      id="filled-basic"
                      name="name"
                      label="Ingredient"
                      value={name}
                      variant="filled"
                      onChange={(e) => handleIngredientChange(e, i)}
                    />
                    <TextField
                      id="filled-basic"
                      name="unit"
                      label="unit"
                      value={unit}
                      variant="filled"
                      onChange={(e) => handleIngredientChange(e, i)}
                    />
                    {ingredients.length !== 1 && (
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() =>
                          removeField(i, ingredients, setIngredients)
                        }
                      >
                        X
                      </Button>
                    )}
                  </div>
                  <div className="btn-box">
                    {ingredients.length - 1 === i && (
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={() => addIngredientField()}
                      >
                        ADD MORE INGREDIENT
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}

            {instructions.map((instruction, i) => {
              return (
                <div className="newRecipe__block" key={i}>
                  <div className="newRecipe__formBlock">
                    <div className="newRecipe__title">Step{i + 1}: </div>
                    <TextField
                      id="filled-basic"
                      label="Add Instruction"
                      value={instruction}
                      variant="filled"
                      onChange={(e) => handleInstructionChange(e, i)}
                    />
                    {instructions.length !== 1 && (
                      <Button
                        className=""
                        variant="outlined"
                        type="button"
                        onClick={() =>
                          removeField(i, instructions, setInstructions)
                        }
                      >
                        X
                      </Button>
                    )}
                  </div>
                  <div className="btn-box">
                    {instructions.length - 1 === i && (
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => addInstructionField()}
                      >
                        ADD MORE STEPS
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}

            <Button
              type="submit"
              variant="contained"
              className="newRecipe__submit"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
}

EditModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  submitRecipe: PropTypes.func.isRequired,
};

export default EditModal;
