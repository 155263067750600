import React, { useState } from "react";
import PropTypes from "prop-types";
import { storage } from "../../firebase";

import "./ImageUpload.scss";

const ImageUpload = ({ imageUrl, setImageUrl }) => {
  const [progress, setProgress] = useState(false);

  const handleChange = (e) => {
    //console.log(e.target.files[0]);
    if (e.target.files[0]) {
      let imageFile = e.target.files[0];
      const uploadTask = storage.ref(`images/${imageFile.name}`).put(imageFile);

      uploadTask.on(
        "state_changed",
        () => {
          //progress function ...
          setProgress(true);
        },
        (error) => {
          //error
          console.log(error);
          alert(error.message);
        },
        () => {
          //complete
          setProgress(false);
          storage
            .ref("images")
            .child(imageFile.name)
            .getDownloadURL()
            .then((url) => {
              //post image inside db
              setImageUrl(url);
            });
        }
      );
    }
  };

  return (
    <div className="imageupload">
      {imageUrl ? (
        <>
          <img
            className="imageupload__preview"
            src={imageUrl}
            alt="coverimage"
          />
          <button
            className="imageupload__clear"
            onClick={() => setImageUrl("")}
          >
            X
          </button>
        </>
      ) : (
        <>
          <input
            className="imageupload__button"
            type="file"
            onChange={handleChange}
          />
          <span className="imageupload__button_cover">
            {progress ? "Uploading..." : "Upload an image"}
          </span>
        </>
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  setImageUrl: PropTypes.func.isRequired,
};

export default ImageUpload;
