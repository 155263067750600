import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { auth } from "./../../firebase";

export const signinUserSuccess = (user) => {
  return { type: types.LOGIN_USER_SUCCESS, user };
};

export const createUserSuccess = (user) => {
  return { type: types.CREATE_USER_SUCCESS, user };
};

export const userLoggedIn = (user) => {
  return { type: types.USER_LOGGED_IN, user };
};

export const userLoggedOut = (user) => {
  return { type: types.USER_LOGGED_OUT, user };
};

export const createUser = (email, password, username) => {
  return function (dispatch) {
    dispatch(beginApiCall());
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        dispatch(
          createUserSuccess({
            name: username,
            email: authUser.email,
            inital: username[0],
          })
        );

        return authUser.user.updateProfile({
          displayName: username,
        });
      })
      .catch((error) => {
        dispatch(apiCallError(error.message));
        alert(error.message);
      });
  };
};

export const signinUser = (email, password) => {
  return function (dispatch) {
    dispatch(beginApiCall());
    auth
      .signInWithEmailAndPassword(email, password)
      .then((authUser) => {
        dispatch(
          signinUserSuccess({
            name: authUser.user.displayName,
            email: authUser.user.email,
            inital: authUser.user.displayName[0],
          })
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error.message));
        alert(error.message);
      });
  };
};

export const loadUser = () => {
  return function (dispatch) {
    return auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //user has logged in...
        dispatch(
          userLoggedIn({
            name: authUser.displayName,
            email: authUser.email,
            inital: authUser.displayName[0],
          })
        );
      } else {
        //user has logged out...
        dispatch(userLoggedOut(null));
      }
    });
  };
};
